<template>
  <div class="commomTitleModule">
    <span class="commomTitleModule__lock" v-if="allow">
      <IconSax name="lock" />
    </span>
    <h2
      class="commomTitleModule__title"
      :class="{ commomTitleModule__lock: allow }"
    >
      {{ value }}
    </h2>
  </div>
</template>

<script>
import Lock from "@/components/common/cards/lock/Lock.vue";

export default {
  components: {
    Lock,
  },
  props: {
    allow: {
      type: Boolean,
    },
    value: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.commomTitleModule {
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  &__title {
    color: var(--fontcolor);
    margin-bottom: 0px;
    line-height: 120%;
    @extend .body-semibold-18;
  }
  &__lock {
    color: var(--neutral-gray-600);
    display: flex;
    align-items: flex-start;
    ::v-deep svg {
      width: 20px;
      height: 20px;
    }
    ::v-deep svg path {
      fill: var(--neutral-gray-600);
    }
  }
}
</style>
